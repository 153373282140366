// @flow

import styled, { css } from "styled-components";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { generateTransition, cubic, easeInOut } from "../../Transitions";

import buildColor from "../../ColorPalette";

const checkifExpandedMargin = (quickLinksExpanded) =>
  quickLinksExpanded ? "144px" : "48px";

const getFooterSize = (props) => {
  const { isFooterVisible, device, hasJoinBar, hasBettingError } = props;

  let defaultSize = "48px";

  if (isFooterVisible || device === "tablet") {
    defaultSize = hasJoinBar ? "114px" : "48px";
  } else if (!isFooterVisible && device === "mobile") {
    defaultSize = hasBettingError ? "172px" : "86px";
  }

  return defaultSize;
};

const MainSection = styled.section`
  z-index: 10;
  display: flex;
  align-items: stretch;
  ${(props) => {
    const footerSize = getFooterSize(props);

    return props.device === "mobile" || props.device === "tablet"
      ? css`
          position: relative;
          will-change: padding;
          transition: ${generateTransition(easeInOut, "padding", "200ms")};
          ${(props.isFooterVisible || props.device === "tablet") &&
          css`
            padding-bottom: ${footerSize};
            padding-bottom: calc(
              ${footerSize} + constant(safe-area-inset-bottom)
            );
            padding-bottom: calc(${footerSize} + env(safe-area-inset-bottom));
            min-height: calc(
              100vh - ${props.device === "mobile" ? footerSize : "56px"}
            );
            top: ${props.device === "tablet"
              ? (!props.isHeaderVisible && "0") || "56px"
              : "0"};
          `};
          ${!props.isFooterVisible &&
          !props.isHeaderVisible &&
          props.device === "tablet" &&
          "padding-bottom: 0; min-height: 100vh;"};
          ${!props.isFooterVisible &&
          props.device === "mobile" &&
          css`
            padding-bottom: ${props.isFullScreen ? 0 : footerSize};
            padding-bottom: calc(
              ${props.isFullScreen ? 0 : footerSize} +
                constant(safe-area-inset-bottom)
            );
            padding-bottom: calc(
              ${props.isFullScreen ? 0 : footerSize} +
                env(safe-area-inset-bottom)
            );
            min-height: calc(
              ${props.product && props.product.includes("fdrios")
                  ? "100vh"
                  : "100%"} - ${props.isHeaderVisible ? "48px" : "0"}
            );
          `};
        `
      : css`
          position: absolute;
          top: 56px;
          left: 56px;
          right: 0;
          bottom: 0;
        `;
  }};

  & > div {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
`;

const Header = styled.header`
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  ${(props) =>
    props.device === "mobile"
      ? css`
          position: sticky;
          ${!props.hasDescription &&
          css`
            height: ${props.headerHeight};
          `};
          ${!props.isVisible &&
          css`
            display: none;
          `};
        `
      : css`
          position: fixed;
          height: ${props.headerHeight};
          ${!props.isVisible &&
          css`
            display: none;
          `};
        `};

  &.logo-highlighted {
    padding-top: 0;
    background-color: ${buildColor("white", "100")};
    ${(props) =>
      props.device === "mobile"
        ? css`
            position: fixed;
          `
        : css`
            position: fixed;
            background-color: ${buildColor("blue", "100")};
            top: 120px;

            @media screen and (orientation: landscape) {
              top: 60px;
            }
          `};
  }
`;

const Footer = styled.footer`
  position: fixed;
  z-index: 20;
  ${(props) =>
    props.device === "mobile" || props.device === "tablet"
      ? css`
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          min-height: 48px;
          will-change: transform, opacity;
          transition: ${generateTransition(cubic, "transform")},
            ${generateTransition(easeInOut, "opacity")};
          ${!props.isVisible && props.device === "mobile"
            ? css`
                transform: translate3d(0, 48px, 0);
                display: none;
              `
            : css`
                transform: translate3d(0);
                opacity: 1;
                ${!props.isVisible &&
                css`
                  display: none;
                `};
              `};
        `
      : css`
          width: 56px;
          top: 56px;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: stretch;

          &::before {
            content: "";
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            width: 56px;
            height: 48px;
            background-image: linear-gradient(
              to top,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 0.12)
            );
          }
        `};
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: ${({ noBackground, rgBackground }) => {
    if (noBackground) {
      return "transparent";
    }
    if (rgBackground) {
      return buildColor("blue", "000");
    }

    return buildColor("blue", "000");
  }};
  ${(props) =>
    props.hasMarginTop &&
    css`
      margin-top: 56px;
    `}
  ${(props) =>
    props.expandedMarginTop &&
    css`
      margin-top: 152px;
    `}
  ${(props) =>
    props.centerItems &&
    css`
      align-items: center;
    `};
  ${(props) =>
    props.alignment === "center" &&
    css`
      @media (min-width: 486px) {
        max-width: 414px;
        padding-top: ${props.topGap && "8px"};
      }

      @media (min-width: 1200px) {
        max-width: 485px;
      }

      @media (min-width: 1366px) {
        max-width: 552px;
      }
    `};
  ${(props) =>
    props.isFixed &&
    css`
      position: fixed;
      bottom: 48px;
      bottom: calc(48px + constant(safe-area-inset-bottom));
      bottom: calc(48px + env(safe-area-inset-bottom));
      top: ${props.device === "tablet" ? "56px" : "48px"};
      left: 0;
    `};
  ${(props) =>
    props.isFullHeight &&
    css`
      height: 100vh;
    `};
`;

const Tracks = styled.div`
  ${(props) =>
    props.loading === "true" &&
    css`
      height: 0;
      overflow: hidden;
    `};
  ${(props) =>
    props.device === "tablet" &&
    css`
      padding-top: 56px;
    `};

  & > section:not(:first-of-type) {
    margin-top: 8px;
  }
`;

const Banner = styled.img`
  width: 100%;
  height: auto;
  ${(props) =>
    props.device !== "mobile" &&
    css`
      margin-bottom: 8px;
      border-radius: 2px;
    `};
`;

const LoadingAppContainer = styled.div.attrs({
  "data-qa-label": "loading-app-container"
})`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MainTool = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  z-index: 0;
  /* stylelint-disable */
  ${(props) =>
    props.isBetting
      ? css`
          min-height: calc(100vh - 85px);
          padding-bottom: 85px;
        `
      : css`
          min-height: calc(100vh - 48px);
          padding-bottom: 0;
        `} /* stylelint-enable */

  &::before {
    content: "";
    position: fixed;
    z-index: 0;
    top: 48px;
    left: 0;
    right: 0;
    height: 48px;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.12)
    );
  }
`;

const WagerRewardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: ${(props) =>
    props.isTablet
      ? `16px 16px ${props.isFixed ? "120px" : "16px"} 16px`
      : "0"};
  ${(props) =>
    props.isMobile &&
    css`
      padding-top: 48px;
    `}
  ${(props) =>
    props.isTablet &&
    css`
      margin-top: 48px;
    `}
  position: ${(props) => (props.isFixed ? "fixed" : "inherit")};
`;

export const FullScreenPage = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
`;

const GridContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 239px);
  display: flex;
`;

const HomeGridContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  width: 100%;
  ${({ device }) =>
    device === "desktop" &&
    css`
      background-color: ${buildColor("blue", "000")};
    `}
  ${({ useStoryblokStyle }) =>
    useStoryblokStyle &&
    css`
      &
        > .section-wrapper-visible:nth-last-child(
          1 of .section-wrapper-visible
        ) {
        margin-bottom: 0;
      }
    `}
`;

const HomeFullRow = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-bottom: ${(props) =>
    props.hasMarginBottom
      ? `${props.device === "desktop" ? "12px" : "8px"}`
      : 0};

  &:empty {
    display: none;
  }

  &:first-of-type {
    margin-top: ${({ hasMarginTopFixed, quickLinksExpanded }) =>
      hasMarginTopFixed ? checkifExpandedMargin(quickLinksExpanded) : 0};
  }
`;

const HomeLeftColumn = styled.div`
  width: ${isTvg5() ? "70%" : "686px"};
  padding: 12px 12px 0;

  &:empty {
    display: none;
  }
`;

const HomeRightColumn = styled.div`
  width: ${isTvg5() ? "30%" : "337px"};
  padding: 12px 12px 0 0;

  &:empty {
    display: none;
  }

  & > section,
  & > div {
    margin-bottom: 12px;
  }
`;

const SeoFooterRow = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-top: 8px;

  &:empty {
    display: none;
  }
`;

const GridWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default {
  MainSection,
  Header,
  Footer,
  Page,
  Tracks,
  Banner,
  LoadingAppContainer,
  MainTool,
  WagerRewardsContainer,
  FullScreenPage,
  GridContainer,
  HomeGridContainer,
  HomeFullRow,
  HomeLeftColumn,
  HomeRightColumn,
  SeoFooterRow,
  GridWrapper
};
