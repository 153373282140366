// @flow
import type { UserInfo } from "@tvg/types/User";

export type UserData = {
  error: boolean | null,
  hasRequested: boolean,
  logged: boolean,
  logging: boolean,
  user: UserInfo,
  wasLogin: boolean,
  userLogin: boolean,
  userLogout: boolean,
  loginPin: number,
  tvg3token: string
};

type UserSessionSuccessAction = {
  type: "USER_DATA_UPDATE",
  payload: UserData
};

export type SetUserLoggedState = {
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged: boolean
  }
};

export type SetUserAccountNumber = {
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber: string
  }
};

export type UserActions =
  | UserSessionSuccessAction
  | SetUserAccountNumber
  | SetUserLoggedState;

export const getUserData = (userData: UserData): UserSessionSuccessAction => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setUserLoggedState = (isLogged: boolean): SetUserLoggedState => ({
  type: "USER_LOGGED_STATE",
  payload: {
    isLogged
  }
});

export const setUserAccountNumber = (
  accountNumber: string
): SetUserAccountNumber => ({
  type: "USER_ACCOUNT_NUMBER",
  payload: {
    accountNumber
  }
});
