// @flow
import { createStore, compose, applyMiddleware } from "redux";
import type { Store } from "redux";
import thunk from "redux-thunk";
import reducers, { initialState, type State } from "./reducers";

let composeEnhancers = compose;

/* eslint-disable */
composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable */

export default (state: State = initialState): Store<State, *> => {
  const middlewares = [thunk];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  // $FlowFixMe
  const store = createStore(reducers, state, enhancer);

  // $FlowFixMe
  if (module.hot) {
    module.hot.accept("./reducers", () =>
      // $FlowFixMe
      System.import("./reducers").then((reducerModule) =>
        store.replaceReducer(reducerModule.default)
      )
    );
  }

  return store;
};
