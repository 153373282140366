// @flow
import { combineReducers } from "redux";

import wagerRewardsReducer, {
  type State as wagerRewardsState,
  initialState as initialWagerRewardsState
} from "@tvg/wager-rewards/src/reducers";

import capiReducer, {
  type State as CapiState,
  initialState as initialCapiState
} from "./App/capiReducer";

import userDataReducer, {
  type State as UserState,
  initialState as initialUserState
} from "./App/userDataReducer";

export type State = {
  capi: CapiState,
  userData: UserState,
  wagerRewards: wagerRewardsState
};

export const initialState = {
  capi: initialCapiState,
  userData: initialUserState,
  wagerRewards: initialWagerRewardsState
};

export default combineReducers({
  capi: capiReducer,
  userData: userDataReducer,
  wagerRewards: wagerRewardsReducer
});
