// @flow
import "isomorphic-fetch";
import React, { type ComponentType } from "react";
// $FlowFixMe
import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import configureStore from "./configureStore";
import MainComponent from "./pages";

const preloadedState = window.__WAR_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
delete window.__WAR_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

const store = configureStore(preloadedState);

const hotRender = (App: ComponentType<*>) =>
  hydrateRoot(
    // $FlowFixMe
    document.getElementById("war"),
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );

export default hotRender(MainComponent);
